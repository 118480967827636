import {request} from "./config"

let NewUrl = ''

// 获取文章详情
export const books_read = (params) => request({
    method: 'get',
    url: NewUrl + 'web/books/read',
    data: params
})

// 获取书籍目录
export const books_directory = (params) => request({
    method: 'get',
    url: NewUrl + 'web/books/directory',
    data: params
})

// 扣书币
export const pay_coin = (params) => request({
    method: 'post',
    url: NewUrl + 'web/books/pay_coin',
    data: params
})

// 订阅所有章节
export const pay_all = (params) => request({
    method: 'post',
    url: NewUrl + 'api/payment/oneClickAllSubscribe',
    data: params
})

// 获取展示书籍详情
export const books_detail= (params) => request({
    method: 'get',
    url: NewUrl + 'web/books/detail',
    data: params
})

// 获取展示书籍评论
export const books_review= (params) => request({
    method: 'get',
    url: NewUrl + 'web/books/review',
    data: params
})

// 获取评论书籍
export const add_review= (params) => request({
    method: 'get',
    url: NewUrl + 'web/books/add_review',
    data: params
})

// 获取展示书籍关联信息
export const detail_sec= (params) => request({
    method: 'get',
    url: NewUrl + 'web/books/detail_sec',
    data: params
})

// 作品点赞
export const like_books= (params) => request({
    method: 'post',
    url: NewUrl + 'web/books/like',
    data: params
})

// 作品加入收藏
export const favorites= (params) => request({
    method: 'post',
    url: NewUrl + 'web/books/favorites',
    data: params
})

// 作品评分
export const submit_score= (params) => request({
    method: 'get',
    url: 'api/score/sumbitScore',
    data: params
})
// 投推荐票
export const throw_ticket= (params) => request({
    method: 'post',
    url: 'api/ticket/monthVote',
    data: params
})
// 道具列表
export const get_gift_list= (params) => request({
    method: 'post',
    url: 'api/common/propList',
    data: params
})
// 道具打赏
export const giveGift= (params) => request({
    method: 'post',
    url: 'api/prop/giveProp',
    data: params
})
// 粉丝排行榜
export const getFansRank= (params) => request({
    method: 'post',
    url: 'api/books/bookFenRank',
    data: params
})
// 作者操作评论
export const authorCommentOperation= (params) => request({
    method: 'post',
    url: 'api/users/authorHandleComment',
    data: params
})
// 用户删除自己评论
export const delete_self_comment= (params) => request({
    method: 'post',
    url: 'api/users/delComment',
    data: params
})
// 点赞段落
export const thumbsUpParagraph= (params) => request({
    method: 'post',
    url: 'api/users/likeComment',
    data: params
})

