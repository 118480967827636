<template>
  <div class="rank_item" flex align between>
    <div class="rank_item_left">
      <div class="rank_one">
        <img
          v-if="
            options.cover_image &&
            options.cover_image.match(/.*(\.png|\.jpg|\.jpeg|\.gif)$/)
          "
          :src="options.cover_image"
          alt=""
          width="100%"
          height="100%"
          @click="book_info(options.id)"
          :onerror="errorImg01"
        />
        <VIPShow
          :is_vip="options.is_vip"
          :is_free="options.charge_type"
          :css_style="{ fontSize: '12px', transform: 'scale(0.95)' }"
        ></VIPShow>
      </div>
      <div class="icon_box" v-if="is_show">
        <!-- icon-paiming -->
        <i
          class="iconfont icon-paiming3"
          :class="[
            index === 0 ? 'gold' : '',
            index === 1 ? 'silver' : '',
            index === 2 ? 'copper' : '',
          ]"
        ></i>
        <span :style="{ fontSize: size_big(index) + 'em' }">
          {{ index + 1 }}
        </span>
      </div>
      <!-- <div :class="options.is_show ? 'rank_item_index' : 'item_unshow'" v-text="index + 1">
       
      </div> -->
    </div>
    <div class="rank_item_right" flex column between>
      <p p-font @click="book_info(options.id)" class="right_name">
        {{ options.name || "--" }}
      </p>
      <div flex>
        <p black class="rank_item_worker">
          <span
            @click="options.author_id ? author_info(options.author_id) : ''"
            :class="options.author_id ? 'intro_author' : 'un_author'"
            ><i class="iconfont icon-touxiang"></i
            >{{ options.author || "--" }}</span
          >
        </p>
        <p
          black
          class="rank_item_status rank_item_label"
          v-if="options.labels"
          @click.stop="go_class(options)"
        >
          <span
            v-text="options.labels ? options.labels.name : '无'"
            ellipsis
          ></span>
        </p>
        <span black class="rank_item_status">{{
          options.serial_status || "--"
        }}</span>
      </div>
      <p black words-hidden class="rank_item_bookinfo">
        {{ options.intro || "无" }}
      </p>
      <div flex between align>
        <p black class="word_num">字数：{{ options.total_words || "--" }}万</p>
        <div>
          <button
            type="button"
            class="key_button"
            :class="
              options.is_col === 0 ? 'rank_bookrack_button' : 'grey_button'
            "
            @click="add_bookrack"
            v-text="options.is_col === 0 ? '加入书架' : '已在书架'"
          ></button>
          <button
            type="button"
            class="rank_bookinfo_button key_button"
            @click="book_info(options.id)"
          >
            书籍详情
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { favorites } from "@/https/read_books.js";
export default {
  data() {
    return {
      brack_flag: localStorage.getItem("api_token") ? true : false,
      errorImg01:
        'this.src="' +
        require("../../../../public/static/default_book.png") +
        '"',
    };
  },
  props: {
    options: {
      type: Object,
    },
    index: {
      type: Number,
    },
    is_show: {
      type: Boolean,
    },
  },
  computed: {
    size_big() {
      return function (index) {
        if (index >= 99) {
          return 0.8;
        } else {
          return 1;
        }
      };
    },
  },
  methods: {
    // 加入书架
    add_bookrack() {
      if (this.brack_flag) {
        let params = {
          books_id: this.options.id,
        };
        if (this.options.is_col === 0) {
          favorites(params).then((res) => {
            if (res.code === 0) {
              this.options.is_col = 1;
              this.$message.success("收藏成功");
            } else {
              this.$message.warning(res.msg);
            }
          });
        }
      } else {
        this.$message.warning("请先登录");
        this.$store.commit("set_login_type", true);
      }
    },
    look_bookinfo(id) {
      const href = this.$router.push({
        path: "/show_book",
        query: {
          id: id,
        },
      });
      // 进入书籍详情界面
      // 把options的数据传递过去或者将options.id传过去
    },
    // 进入书籍详情界面
    book_info(id) {
      const { href } = this.$router.resolve({
        path: "/show_book",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    // 作者详情
    author_info(id) {
      const { href } = this.$router.resolve({
        path: "/show_author",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    // 去书库
    go_class(row) {
      if (row.labels) {
        this.$router.push({
          path: "/stack_room",
          query: {
            label: `${row.labels.name}`,
          },
        });
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
* {
  font-size: 14px;
}
.rank_item {
  width: 100%;
  height: 100%;
}
.rank_item_left {
  width: 120px;
  height: 170px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  .rank_item_index {
    display: block;
    width: 35px;
    line-height: 30px;
    text-align: center;
    font-size: 13px;
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(249, 94, 90, 1);
    border: none;
    border-radius: 35px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .icon_box {
    position: absolute;
    top: 0;
    left: 4px;
    color: #ffffff;
    .iconfont {
      color: rgba(149, 149, 149, 0.8);
    }
    i {
      font-size: 30px;
      opacity: 0.8;
    }
    span {
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .item_unshow {
    display: none;
  }
  img {
    cursor: pointer;
  }
}
.rank_one {
  width: 120px;
  height: 170px;
  overflow: hidden;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05), 0 0 5px #f9f2e9 inset;
  position: relative;
  img {
    cursor: pointer;
    width: 120px;
    height: 170px;
    transition: all 0.5s;
  }
  img:hover {
    transform: scale3d(1.1, 1.1, 1.1);
  }
}
.rank_item_right {
  width: calc(100% - 120px - 40px);
  height: 100%;
  padding: 15px 0;
  .right_name {
    font-size: 16px;
    cursor: pointer;
  }
  .right_name:hover {
    color: #318dfeff;
  }
  .rank_item_status {
    width: 80px;
    padding-left: 30px;
    border-left: 1px solid rgb(209, 208, 208);
    margin-right: 25px;
    line-height: 20px;
    text-align: center;
    span {
      display: inline-block;
      width: 100%;
    }
  }
  .rank_item_worker {
    // width: 56px;
    min-width: 63px;
    line-height: 20px;
    // cursor: pointer;
    padding-right: 15px;
    > span {
      display: inline-block;
    }
    i {
      color: #dbdbdb;
      margin-right: 4px;
    }
    .intro_author {
      font-size: 14px;
      font-weight: normal;
      cursor: pointer;
      color: #333;
    }
    .intro_author:hover {
      color: rgba(79, 156, 251, 1);
    }
    .un_author {
      font-size: 14px;
      font-weight: normal;
      color: #333;
    }
  }

  // .rank_item_worker:hover {
  //   color: rgba(79, 156, 251, 1);
  // }
  .rank_item_bookinfo {
    width: 75%;
    letter-spacing: 2px;
    // 超出两行设置省略号
    -webkit-line-clamp: 2;
  }
  .key_button {
    width: 100px;
    height: 35px;
    outline: none;
    border: 0;
    background-color: transparent;
    border-radius: 5px;
    margin-right: 20px;
    font-size: 14px;
  }
  .rank_bookrack_button {
    border: 1px solid #409eff;
    color: #409eff;
    cursor: pointer;
  }
  .rank_bookinfo_button {
    cursor: pointer;
  }
  .rank_bookrack_button:hover,
  .rank_bookinfo_button {
    color: #ffffff;
    background-color: #409eff;
  }
  .rank_bookinfo_button:hover {
    opacity: 0.8;
  }
  .grey_button {
    border: 1px solid #999;
    color: #999;
    // cursor: ;
  }
}
.gold {
  color: #0076ea !important;
}
.silver {
  color: #36d6fe !important;
}
.copper {
  color: #5ac5fd !important;
}
.word_num {
  color: #999999;
  font-size: 12px;
}
.rank_item_label {
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}
</style>