import {request} from "./config"

// let NewUrl = 'http://192.168.0.128:81'
let NewUrl = '';


// 根据内容及标签搜索
export const search = (params) => request({
    method: 'get',
    url: NewUrl + 'web/search',
    data: params
})




