<template>
  <div class="jy_search">
    <div class="search_top">
      <topHeader @search="search" :val="text"></topHeader>
    </div>
    <div class="container search_content">
      <div class="search_menu" flex align>
        <div
          v-for="item in menu_list"
          :key="item.id"
          @click="choosed(item)"
          :class="curren_id === item.id ? 'choose_it' : ''"
        >
          {{ item.name }}
        </div>
      </div>
      <NoneData v-if="showItemArr.length === 0"></NoneData>
      <div class="search_show" v-if="showItemArr.length > 0">
        <showItem
          :options="item"
          v-for="item in showItemArr"
          :key="item.id"
          :is_show="false"
        ></showItem>
      </div>
      <div class="search_bottom" v-if="showItemArr.length > 0">
        <cPage :page="page" :callBack="loadData"></cPage>
      </div>
    </div>
  </div>
</template>
<script>
import showItem from "@/views/ranking/components/rank-show-item.vue";
import topHeader from "@/components/main/top-header";
import cPage from "@/components/page/c-page.vue";
import { search } from "@/https/search.js";
import Bus from "@/Bus/Bus.js";

export default {
  components: {
    topHeader,
    showItem,
    cPage,
  },
  provide() {
    return {
      is_search: true,
    };
  },
  data() {
    return {
      showItemArr: [],
      text: sessionStorage.getItem("search_val") || "",
      page: { page: 1, limit: 10, total: 0 },
      menu_list: [
        { name: "综 合", id: 1 },
        { name: "点 击", id: 2 },
        { name: "收 藏", id: 3 },
        { name: "点 赞", id: 4 },
      ],
      curren_id: "",
    };
  },
  created() {
    let id = Number(sessionStorage.getItem("curren_id"));
    if (id) {
      this.curren_id = id;
    } else {
      this.curren_id = this.menu_list[0].id;
    }
  },
  methods: {
    // 分页
    loadData() {
      this.search_data();
    },
    // 点击搜索
    search() {
      this.search_data();
    },
    // 选中不同菜单
    choosed(item) {
      sessionStorage.setItem("curren_id", item.id);
      this.curren_id = item.id;
      //根据类型获取数据
      // this.loadData(this.curren_id)
      this.search_data();
    },
    // 搜索
    search_data() {
      let params = {
        name: this.text,
        type: this.curren_id,
        page: this.page.page,
        limit: this.page.limit,
      };
      search(params).then((res) => {
        this.showItemArr = res.data;
        this.page.total = res.total;
      });
    },
  },
  mounted() {
    // this.loadData(1)；
    // 默认加载数据
    this.search_data();
    Bus.$on("val", (el) => {
      this.text = el;
    });
  },
};
</script>
<style lang="scss" scoped>
.search_top {
  .top_header {
    // background-color: rgba(248, 243, 233, 1);
    background-color: #ffffff;
    height: 56px;
    /deep/.el-input__inner {
      height: 36px !important;
      border-radius: 50px !important;
    }
    /deep/.input_arr {
      height: 36px;
      line-height: 36px;
    }
    /deep/.seach {
      width: 45px;
      background: #eeeeee;
      color: #999999;
      font-size: 20px;
      height: 36px;
      border-radius: 0 50px 50px 0 !important;
    }
  }
}
.jy_search {
  background-color: #fafcff;
  min-height: 100vh;

  .search_top {
    width: 100%;
    background-color: #fff;
    // >.top_header {
    //   >.input_arr {
    //     display: none !important;
    //   }
    // }
  }

  .search_content {
    .search_box {
      margin: 30px auto;
      flex-wrap: nowrap;
      width: 460px;
      height: 32px;
      line-height: 32px;
    }

    .search_content_sea {
      width: 17%;
      line-height: 32px;
      text-align: center;
      background: #eeeeee;
      color: #9b9b9b;
      border: 1px solid #eeeeee;
      font-size: 13px;
      // padding: 0 2px;
      border-radius: 0 4px 4px 0;
      margin-left: -1px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    .search_show {
      padding: 8px 150px 20px;

      .rank_item {
        height: 200px;
        border-bottom: 1px solid rgba(228, 228, 228, 1);
        padding: 0 0 0 20px;
      }
    }

    .search_menu {
      width: 100%;
      justify-content: start;
      font-size: 0;
      margin-top: 20px;
      padding-left: 150px;
      border-bottom: 1px solid;
      border-image: linear-gradient(
          to right,
          rgba(228, 228, 228, 0.2) 0%,
          rgba(209, 205, 205, 1) 50%,
          rgba(228, 228, 228, 0.2) 100%
        )
        2 2 2 2;

      > div {
        font-size: 18px;
        width: 120px;
        line-height: 55px;
        cursor: pointer;
        margin-right: 20px;
        text-align: left;
      }

      > div:hover {
        color: #409eff;
      }

      .choose_it {
        color: #409eff;
        font-weight: bold;
      }
    }

    .search_bottom {
      padding-bottom: 10px;
      width: 100%;
      height: 50px;
    }
  }
}

/deep/ .search_top {
  box-shadow: 5px 0 5px #d2d2d2;
}

/deep/ .rank_item_bookinfo {
  color: #666666;
}
</style>
